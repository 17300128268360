import { useContext } from 'react'
import { NotificationsContext } from 'context/notifications/toastContext'
import useApi from 'services/api/fetchData'
import { AppRoutes } from 'config'
import { useQueryClient } from '@tanstack/react-query'

export const useCreateCrews = () => {
	const { showSuccess, showError } = useContext(NotificationsContext)
	const { putRequest } = useApi()
	const queryClient = useQueryClient()

	const createCrew = async (Crew: unknown) => {
		try {
			const response = await putRequest(
				AppRoutes.serverCrewRoutes.createCrew,
				Crew
			)
			queryClient.refetchQueries(['crews'])
			showSuccess('Crew created successfully')
			return response.data
		} catch (error) {
			showError((error as Error).message)
			throw new Error((error as Error).message)
		}
	}
	return { createCrew }
}
